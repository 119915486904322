<template>
<div class="home">
<div class="mian mainHome">
<div class="wrap">

<div class="home_pre clearfloat" style="height:98px;">
<div class="home_img">
<img src="../assets/img/yian02.png" alt="" style="width: 107px;height: 108px;position: absolute;z-index: 10;overflow: hidden;margin-top: -2px;">
</div>
<div class="inner" style="width:900px;height:90px;height:98px">
<div style="position:relative;height:98px;width:900px;text-align:center">
<a v-if="newsTop.url && newsTop.url !== ''" target="_blank" :href="newsTop.url" style="width:100%;height:45px;color:#0061B2;font-size:28px;font-weight:bolder;line-height:10px;text-decoration: none;">{{newsTop.title}}</a>
<a v-else target="_blank" :href="`/detail/${newsTop.entTChannelUuid}/${newsTop.entTArticleUuid}`" style="width:100%;height:45px;color:#0061B2;font-size:28px;font-weight:bolder;line-height:10px;text-decoration: none;">{{newsTop.title}}</a>
<p style="text-indent: 2em;font-size: 14px;line-height: 22px;color: #515151;margin: -13px 0px;overflow: hidden;height: 48px;width: 97%;display:block;text-align: left;">
                {{newsTop.artDesc | ellipsis}}
              </p>
</div>
</div>
</div>


<div class="homeNews">
<el-row :gutter="40" style="margin:0px;">
<el-col :span="12" style="padding:0px">
<div class="homeNannerBox" v-if="activeNewsHd == 0">
<div class="swiper-container bannerHomeSec01" id="bannerHomeSec01" style="height:370px;width:560px;">
<div class="swiper-wrapper">
<div class="swiper-slide" v-for="(item, index) in newsLeft01" :key="index">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
<img :src="item.thumb1" alt="">
<p class="single-line">{{item.title}}</p>
</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
<img :src="item.thumb1" alt="">
<p class="single-line">{{item.title}}</p>
</a>
</div>
</div>
<div class="swiper-pagination swiper-pagination-sec01"></div>
</div>
</div>

<div class="homeNannerBox" v-if="activeNewsHd == 1">
<div class="swiper-container bannerHomeSec01" id="bannerHomeSec02" style="height:370px;width:560px;">
<div class="swiper-wrapper">
<div class="swiper-slide" v-for="(item, index) in newsLeft02" :key="index">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
<img :src="item.thumb1" alt="">
<p class="single-line">{{item.title}}</p>
</a>
<a v-else target="_blank" :href="`/detail/01370001/${item.entTArticleUuid}`">
<img :src="item.thumb1" alt="">
<p class="single-line">{{item.title}}</p>
</a>
</div>
</div>
<div class="swiper-pagination swiper-pagination-sec02"></div>
</div>
</div>
</el-col>
<el-col :span="12" style="padding:0px;">
<div class="homeNewsBox_1">
<div class="homeNewsHd">
<span class="homeNewsHdInner" :class="{active : activeNewsHd == index}" v-for="(item, index) in newsHdList" :key="index + 'com1'" @mouseenter="mouseenterNewsHd(index)">
<a :href="item.url" target="_balnk">{{ item.title }}</a>
</span>
<a href="/listNews/0137" target="_balnk" class="more" style="width: 40px;height: 30px;float: right;text-align: center;display: block;margin-top: 10px;font-size: 13px;color: #0061B4;">+更多</a>
</div>
<div class="tj" v-if="activeNewsHd == 0">
<a v-if="newsRight01First.url && newsRight01First.url !== ''" target="_blank" :href="newsRight01First.url">{{newsRight01First.title | ellipsis1}}</a>
<a v-else target="_blank" :href="`/detail/${newsRight01First.entTChannelUuid}/${newsRight01First.entTArticleUuid}`">{{newsRight01First.title | ellipsis1}}</a>
<p>{{newsRight01First.artDesc | ellipsis2}}</p>
</div>

<div class="tj" v-if="activeNewsHd == 1">
<a v-if="newsRight02First.url && newsRight02First.url !== ''" target="_blank" :href="newsRight02First.url">{{newsRight02First.title | ellipsis1}}</a>
<a v-else target="_blank" :href="`/detail/01370001/${newsRight02First.entTArticleUuid}`">{{newsRight02First.title | ellipsis1}}</a>
<p>{{newsRight02First.artDesc | ellipsis2}}</p>
</div>
<div class="clearLine"></div>
<ul class="com" v-if="activeNewsHd == 0">
<li v-for="(item,index) in newsRight01" :key="index" >
<div class="comDate">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</div>
<p class="comLi">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">{{item.title}}</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{item.title}}</a>
</p>
</li>
</ul>

<ul class="com" v-if="activeNewsHd == 1">
<li v-for="(item,index) in newsRight02" :key="index" >
<div class="comDate">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</div>
<p class="comLi">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">{{item.title}}</a>
<a v-else target="_blank" :href="`/detail/01370001/${item.entTArticleUuid}`">{{item.title}}</a>
</p>
</li>
</ul>
                
              </div>
</el-col>
</el-row>
</div>
<div class="clear" style="width:100%;height:20px;overfload:hidden;diaplay:block;clear:both;"></div>


<div class="homeNotice clearfloat" style="width:1000px;height:108px;">
<img :src="bannerImg" alt="" style="height:108px;width:1000px;">
</div>
<div class="clear" style="width:100%;height:20px;overfload:hidden;diaplay:block;clear:both;"></div>


<div class="homeDec02">
<el-row :gutter="40" style="margin:0;">
<el-col :span="12" style="padding:0;">
<div class="homeNewsPeriodical" style="width:100%;">
<div class="homeNewsHd" style="width:730px">
<span class="homeNewsHdInner" :class="{active : activeNewsHd1 == index}" v-for="(item, index) in newsHdList1" :key="index +'hom'" @mouseenter="mouseenterNewsHd1(index)">
<a :href="item.url" target="_balnk">{{ item.title }}</a>
</span>
</div>
<div class="homeDec02Top">
<div class="homeDec02Img" style="height:200px;width:285px;overflow:hidden;margin-top:20px;border:none;padding:0;">
<img src="../assets/img/yian04.jpg" alt="">
</div>
<el-col :span="24" style="padding-left:15px;position:absolute;left:290px;top:53px;height:235px;">
<ul class="comul" v-if="activeNewsHd1 == 0">
<li v-for="(item,index) in homeNewsPeriodicalList1" :key="index">
<div class="news_list_time fr">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</div>
<p class="news_list_inner single-line">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">{{item.title}}</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{item.title}}</a>
</p>
</li>
</ul>
<ul class="comul" v-if="activeNewsHd1 == 1">
<li v-for="(item,index) in homeNewsPeriodicalList2" :key="index">
<div class="news_list_time fr">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</div>
<p class="news_list_inner single-line">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">{{item.title}}</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{item.title}}</a>
</p>
</li>
</ul>
<ul class="comul" v-if="activeNewsHd1 == 2">
<li v-for="(item,index) in homeNewsPeriodicalList3" :key="index">
<div class="news_list_time fr">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</div>
<p class="news_list_inner single-line">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">{{item.title}}</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{item.title}}</a>
</p>
</li>
</ul>
</el-col>
<div class="right">
<div class="tit">
<div class="b">省外区域分公司</div>
</div>
<a href="/" class="company" style=" width: 224px;height: 60px;border: 1px solid rgb(241, 241, 241);padding: 3px;margin-bottom: 5px;overflow: hidden;display: block;">
<img src="../assets/img/yian06.jpg" style="width:224px;height:60px;">
</a>
<a href="/" class="company" style=" width: 224px;height: 60px;border: 1px solid rgb(241, 241, 241);padding: 3px;margin-bottom: 5px;overflow: hidden;display: block;">
<img src="../assets/img/yian07.jpg" style="width:224px;height:60px;">
</a>
<a href="/" class="company" style=" width: 224px;height: 60px;border: 1px solid rgb(241, 241, 241);padding: 3px;margin-bottom: 5px;overflow: hidden;display: block;">
<img src="../assets/img/yian05.jpg" style="width:224px;height:60px;">
</a>
</div>
</div>
<div class="clear" style="width:100%;height:20px;overfload:hidden;diaplay:block;clear:both;"></div>
<div class="homeNotice clearfloat" style="width:1000px;height:108px;">
<img :src="MediumbannerImg" alt="" style="height:108px;width:1000px;">
</div>
</div>
</el-col>
</el-row>
</div>

        
        <div class="clear" style="width:100%;height:20px;overfload:hidden;diaplay:block;clear:both;"></div>

<div class="homeDec03" >
<div class="homeNewsPeriodical">
<div class="homeNewsHd">
<span class="homeNewsHdInner active">
<a target="_blank" href="/list/01460004">学习园地</a>
</span>
<a href="/list/01460004" target="_blank" class="more">+更多</a>
</div>
<div class="homeDec02Bottom">
<ul class="comUL" style="margin-top: 10px; margin-left:0px;">
<li v-for="(item,index) in homeNewsDjList" :key="index">
<div class="news_list_time fr">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</div>
<p class="news_list_inner single-line">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">{{item.title}}</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{item.title}}</a>
</p>
</li>
</ul>
</div>
</div>
<div class="homeNewsBasic">
<div class="homeNewsHd">
<span class="homeNewsHdInner active">
<a href="/list/0147" target="_blank">职工文苑</a>
</span>
<a href="/list/0147" target="_blank" class="more">+更多</a>
</div>
<div class="homeDec03Box">
<div class="homeDec02Bottom">
<ul class="comUL" style="margin-top: 10px;">
<li v-for="(item,index) in homeNewsXxjyList" :key="index">
<div class="news_list_time fr">{{ dayjs(item.publishDate).format('YYYY-MM-DD') }}</div>
<p class="news_list_inner single-line">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">{{item.title}}</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">{{item.title}}</a>
</p>
</li>
</ul>
</div>
</div>
</div>
<div class="rightPart">
<a href="/report" target="_blank">
<img src="../assets/img/yianjw.jpg">
</a>
<a href="http://www.gszcjcw.com/" target="_blank" >
<img src="../assets/img/yianOAN.jpg" style="margin-top:20px">
</a>
</div>
</div>
<div class="clear" style="width:100%;height:20px;overfload:hidden;diaplay:block;clear:both;"></div>

        
        
<div class="about">
<div class="homeNewsHd">
<span class="homeNewsHdInner" :class="{active : activeNewsHd2 == index}" v-for="(item, index) in newsHdList2" :key="index + 'sep'" @mouseenter="mouseenterNewsHd2(index)">
<a :href="item.url" target="_balnk">{{ item.title }}</a>
</span>
<a href="/listPic/01360005" target="_balnk" class="more" style="width: 40px;height: 30px;float: right;text-align: center;display: block;margin-top: 10px;font-size: 13px;color: #0061B4;">+更多</a>
</div>
</div>


<div class="specail">
<div class="specailBox" style="height: 240px;" v-show="activeNewsHd2==0 && pointImgList01.length!=0 ">
<div class="swiper-container bannerPoint" style="padding-bottom: 10px;" id="bannerPoint01">
<div class="swiper-wrapper">
<div class="swiper-slide" v-for="(item, index) in pointImgList01" :key="index">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
<div class="pic">
<img class="img_c1" :src="item.thumb1" alt="">
</div>
<h3 class="single-line">{{item.title}}</h3>
</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
<div class="pic">
<img class="img_c1" :src="item.thumb1" alt="">
</div>
<h3 class="single-line">{{item.title}}</h3>
</a>
</div>
</div>
</div>
</div>
<div class="specailBox" style="height: 240px;" v-show="activeNewsHd2==1 && pointImgList02.length!=0">
<div class="swiper-container bannerPoint" style="padding-bottom: 10px;" id="bannerPoint02">
<div class="swiper-wrapper">
<div class="swiper-slide" v-for="(item, index) in pointImgList02" :key="index">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
<div class="pic">
<img class="img_c1" :src="item.thumb1" alt="">
</div>
<h3 class="single-line">{{item.title}}</h3>
</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
<div class="pic">
<img class="img_c1" :src="item.thumb1" alt="">
</div>
<h3 class="single-line">{{item.title}}</h3>
</a>
</div>
</div>
</div>
</div>
<div class="specailBox" style="height: 240px;" v-show="activeNewsHd2==2 && pointImgList03.length!=0 ">
<div class="swiper-container bannerPoint" style="padding-bottom: 10px;" id="bannerPoint03">
<div class="swiper-wrapper">
<div class="swiper-slide" v-for="(item, index) in pointImgList03" :key="index">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
<div class="pic">
<img class="img_c1" :src="item.thumb1" alt="">
</div>
<h3 class="single-line">{{item.title}}</h3>
</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
<div class="pic">
<img class="img_c1" :src="item.thumb1" alt="">
</div>
<h3 class="single-line">{{item.title}}</h3>
</a>
</div>
</div>
</div>
</div>
<div class="specailBox" style="height: 240px;" v-show="activeNewsHd2==3 && pointImgList04.length!=0">
<div class="swiper-container bannerPoint" style="padding-bottom: 10px;" id="bannerPoint04">
<div class="swiper-wrapper">
<div class="swiper-slide" v-for="(item, index) in pointImgList04" :key="index">
<a v-if="item.url && item.url !== ''" target="_blank" :href="item.url">
<div class="pic">
<img class="img_c1" :src="item.thumb1" alt="">
</div>
<h3 class="single-line">{{item.title}}</h3>
</a>
<a v-else target="_blank" :href="`/detail/${item.entTChannelUuid}/${item.entTArticleUuid}`">
<div class="pic">
<img class="img_c1" :src="item.thumb1" alt="">
</div>
<h3 class="single-line">{{item.title}}</h3>
</a>
</div>
</div>
</div>
</div>
</div>

<div class="clear" style="width:100%;height:20px;overfload:hidden;diaplay:block;clear:both;"></div>
<div class="link">
<div class="com_t">
<div class="homeNewsHd">
<span class="homeNewsHdInner" :class="{active : activeNewsHd3 == index}" v-for="(item, index) in newsHdList3" :key="index + 'bott'" @mouseenter="mouseenterNewsHd3(index)">
<a href="#">{{ item.title }}</a>
</span>
</div>
</div>
<div class="c" id="link_1" v-if="activeNewsHd3==0">
<a href="http://www.gsjs.gov.cn/" target="_blank">甘肃省建设工程安全质量监督管理局</a>
<a href="http://gs.gov.cn" target="_blank">甘肃省国资委</a>

<a href="https://www.mem.gov.cn/" target="_blank">国家安全生产监督管理局</a>
<a href="http://www.scg.com.cn/" target="_blank">上海建工集团</a>
<a href="http://www.miit.gov.cn/" target="_blank">国家工业和信息化部</a>
<a href="http://www.mohurd.gov.cn/" target="_blank">住房和城乡建设部</a>
<a href="http://www.sasac.gov.cn/" target="_blank">国务院国资委</a>
<a href="http://www.zgjzy.org.cn/" target="_blank">中国建筑业协会网</a>
</div>
<div class="c" id="link_2" v-if="activeNewsHd3==1">
<a href="http://www.azxh.cn/" target="_blank">中国安装协会</a>
<a href="http://www.zgazxxw.com/" target="_blank">中国安装信息网</a>
<a href="http://www.huashi.sc.cn/" target="_blank">四川华西集团</a>

<a href="http://2226050.b2b.tfsb.cn/" target="_blank">云南建工集团</a>

<a href="http://china-epc.com/" target="_blank">中国项目工程管理网</a>
<a href="http://www.cacem.com.cn/" target="_blank">中国施工企业管理协会</a>
<a href="http://www.zgjzy.org.cn/" target="_blank">中国建筑业协会网</a>
</div>
<div class="c" id="link_3" v-if="activeNewsHd3==2">
<a href="http://topic.gansudaily.com.cn" target="_blank">甘肃日报</a>
<a href="http://www.ce.cn/" target="_blank">中国经济网</a>
<a href="http://www.gmw.cn/" target="_blank">光明网</a>
<a href="http://www.qstheory.cn/" target="_blank">求是网</a>
<a href="http://www.xinhuanet.com/" target="_blank">新华网</a>
<a href="http://www.people.com.cn/" target="_blank">人民网</a>
</div>
<div class="c" id="link_4" v-if="activeNewsHd3==3">
<a href="http://www.gsjtw.cc/" target="_blank">甘肃建投网</a>
<a href="http://www.gsjttm.com/" target="_blank">甘肃建投土木工程建设有限公司</a>
<a href="http://www.gsjtky.com/" target="_blank">甘肃建投绿色建材产业发展集团有限公司</a>
<a href="http://www.gsjtxqglgs.com/" target="_blank">甘肃建投兰州新区建设管理有限公司</a>
<a href="http://www.jtdzpt.com/" target="_blank">建投大宗</a>
<a href="http://www.gsjtpxzx.com/" target="_blank">甘肃建投培训中心</a>
<a href="http://www.cgicop.com/" target="_blank">中国甘肃国际经济技术合作总公司</a>
<a href="http://www.gsjtjs.com/" target="_blank">甘肃建投建设有限公司</a>
<a href="http://www.gcigcem.com.cn/" target="_blank">甘肃建投装备制造有限公司</a>
<a href="http://www.gjkygs.com/" target="_blank">甘肃省建筑科学研究院</a>
<a href="http://www.gsjtsd.com/" target="_blank">甘肃建投隧道工程有限公司</a>
<a href="http://www.gsazjsjt.com/" target="_blank">甘肃省安装建设集团公司</a>
<a href="http://www.gs9j.cn/" target="_blank">甘肃第九建设集团公司</a>
<a href="http://www.gs8j.com/" target="_blank">甘肃第八建设集团有限责任公司</a>
<a href="http://www.gs7j.com.cn/" target="_blank">甘肃第七建设集团股份有限公司</a>
<a href="http://www.gsljw.com/" target="_blank">甘肃第六建设集团股份有限公司</a>
<a href="http://www.gs5j.com/" target="_blank">甘肃第五建设集团公司</a>
<a href="http://www.gssjjt.com/" target="_blank">甘肃第四建设集团有限责任公司</a>
<a href="http://www.gssanjian.com/" target="_blank">甘肃第三建设集团公司</a>
<a href="http://www.gsejjt.cn/" target="_blank">甘肃第二建设集团有限责任公司</a>
<a href="http://www.gsyj.com.cn/" target="_blank">甘肃第一建设集团有限责任公司</a>
</div>
<div class="c" id="link_5" v-if="activeNewsHd3==4">
<a href="http://www.hljaz.com/" target="_blank">黑龙江省建筑安装集团有限公司</a>
<a href="http://zjkygroup.com/index.asp" target="_blank">浙江省工业设备安装集团有限公司</a>
<a href="http://www.scic.cn/" target="_blank">四川省工业设备安装公司</a>

<a href="https://siec.scg.com.cn/portal/index/index.htm" target="_blank">上海市安装工程集团有限公司</a>
<a href="http://www.sdsazgs.com/" target="_blank">山东省工业设备安装有限公司</a>
<a href="http://www.jieic.com/" target="_blank">江西省安装工程有限公司</a>
<a href="http://www.fjaz.cn/" target="_blank">福建省工业设备安装有限公司</a>
<a href="http://www.tjjdaz.com/home" target="_blank">天津安装工程有限公司</a>
<a href="https://6bur.cscec.com/" target="_blank">中建六局安装工程有限公司</a>
<a href="http://www.cqaz.com.cn/" target="_blank">重庆工业设备安装集团有限公司</a>
<a href="http://hnaz.com.cn/" target="_blank">湖南省工业设备安装有限公司</a>

<a href="https://eic.cdceg.com.cn/" target="_blank">成都市工业设备安装公司</a>
<a href="http://inco.cscec.com/" target="_blank">中建安装工程有限公司</a>
<a href="http://www.bmeeg.cn/Default.asp" target="_blank">北京市设备安装工程集团</a>
<a href="http://www.chinasilian.com.cn/" target="_blank">中国四联仪器仪表集团有限公司</a>
<a href="http://www.sxaz.com/" target="_blank">陕西建工安装集团有限公司</a>
</div>
</div>
<div class="clear" style="width:100%;height:20px;overfload:hidden;diaplay:block;clear:both;"></div>

</div>
</div>
</div>
</template>
<script>
import Swiper from "swiper"
import { getTopArtList, picArticles, articles, getAdvert } from '@/api/common'
export default {
  name: 'home',
  data(){
    return { 
      activeNewsHd: 0,
      activeNewsHd1:0,
      activeNewsHd2:0,
      activeNewsHd3:0,
      newsHdList2: [
        {
          title: '代表工程',
          url: '/listPic/01380002'
        },
        {
          title: '企业资质',
          url: '/listPic/01360005'
        },
        {
          title: '许可证书',
          url: '/list/01360006'
        },
        {
          title: '企业荣誉',
          url: '/listPic/01380003'
        },
      ],
      newsHdList3: [
        {
          title: '政府机构',
        },
        {
          title: '行业企业',
        },
        {
          title: '媒体链接',
        },
        {
          title: '甘肃建投内部',
        },
        {
          title: '友情单位',
        },
      ],
      newsHdList: [
        {
          title: '公司新闻',
          url: '/list/01370002'
        },
        {
          title: '甘肃建投',
          url: '/list/01370001'
        },
      ],
      newsHdList1:[
        {
          title: '基层动态',
          url: '/list/01370003'
        },
        {
          title: '通知公告',
          url: '/list/01390002'
        },
        {
          title: '行业新闻',
          url: '/list/01460001'
        },
      ],
      activeNewsHdDes02: 0,
      newsHdListDes02: [
        {
          title: '学习教育',
          url: '/list/0076'
        },
        {
          title: '行业动态',
          url: '/list/00700002'
        }
      ],
      newsTop: [],
      newsLeft: [],
      newsRight: [],
      newsLeft01: [],
      newsLeft02: [],
      newsLeft03: [],
      newsRight01: [],
      newsRight02: [],
      newsRight01First:{},
      newsRight02First:{},
      newsRight03: [],
      homeNoticeList: [],
      homeDec02Url: '',
      homeDec02ImgUrl: '',
      homeNewsPeriodicalList1: [],
      homeNewsPeriodicalList2:[],
      homeNewsPeriodicalList3:[],
      homeNewsBasicTop: {},
      newsNotice: [],
      homeNewsBasicList: [],
      homeNewsTopDj: {},
      homeNewsTopXxjy: {},
      homeNewsTopHydt: {},
      homeNewsDjList: [],
      homeNewsXxjyList: [],
      homeNewsHydtList: [],
      pointImgList01:[],
      pointImgList02:[],
      pointImgList03:[],
      pointImgList04:[],
      specailList: [],
      bannerImg:'',
      MediumbannerImg:'',
    }
  },
  mounted(){
    this.getTopArtList()
    this.homeNewsCompany()
    this.bannerHomeSec01()
    this.bannerPoint()
    this.homeNewsPeriodical()
    this.homeNewsDj()
    this.homeNewsXxjy()
    this.Bannerimg()
  },
  filters: {
    //超过100位显示 ...
    ellipsis: function(value) {
      if (!value) return ''
      if (value.length > 120) {
        return value.slice(0, 120) + '...'
      }
      return value
    },
    ellipsis1: function(value) {
      if (!value) return ''
      if (value.length > 20) {
        return value.slice(0, 20) + '...'
      }
      return value
    },
    ellipsis2: function(value) {
      if (!value) return ''
      if (value.length > 54) {
        return value.slice(0, 54) + '...'
      }
      return value
    }
  },
  methods: {
    // 中间banner 和 banner
    Bannerimg() {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        advertType: 1
      }
      getAdvert(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length && (_this.bannerImg = data[0].fileName)
        }
      })

      let data2 = {
        applicationUuid: _this.webId,
        advertType: 3
      }
      getAdvert(data2).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length && (_this.MediumbannerImg = data[0].fileName)
        }
      })

    },
    // 头条
    getTopArtList() {
      var _this = this
      let data = {
        applicationUuid: _this.webId
      }
      getTopArtList(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsTop = data[0]
        }
      })
    },
    // 公司新闻/建投新闻/媒体关注
    homeNewsCompany() {
      var _this = this

      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01370002',
        pageNum: 1,
        pageSize: 8
      }
      articles(data01).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsRight01 = data.rows.slice(1,8)
          _this.newsRight01First = data.rows[0]
        }
      })

      let data02 = {
        entTApplicationUuid: _this.webIdGroup,
        // channelUuid: '01370001',
        channelUuid: '00190001',
        pageNum: 1,
        pageSize: 8
      }
      articles(data02).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsRight02 = data.rows.slice(1,8)
          _this.newsRight02First = data.rows[0]
        }
      })
    },
    mouseenterPeriodical(val) {
      var _this = this

      _this.homeDec02ImgUrl = val.thumb1
      _this.homeDec02Url = val.url
    },
    mouseenterNewsHd(val) {
      var _this = this

      _this.activeNewsHd = val
    },
    mouseenterNewsHd2(val){
      var _this = this

      _this.activeNewsHd2 = val
      if (val == 0) {
        _this.swiper01()
      } else if (val == 1) {
        _this.swiper02()
      }else if (val == 2) {
        _this.swiper03()
      } else {
        _this.swiper04()
      }
      // _this.bannerPoint()
    },
    mouseenterNewsHd3(val){
      var _this = this

      _this.activeNewsHd3 = val

    },
    mouseenterNewsHd1(val){
      var _this = this

      _this.activeNewsHd1 = val
    },
    mouseenterNewsHdDes02(val) {
      var _this = this

      _this.activeNewsHdDes02 = val
    },
    // 焦点图
    bannerHomeSec01(){
      var _this = this

      let data01 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01370002',
        pageNum: 1,
        pageSize: 6
      }
      picArticles(data01).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsLeft01 = data.rows
          this.$nextTick(() => {
            new Swiper("#bannerHomeSec01", {
              effect : 'fade',
              speed: 300,
              autoplay: 2000,
              loop: true,
              pagination : '.swiper-pagination-sec01',
              paginationClickable: true,
              paginationBulletRender: function (swiper, index, className) {
                  return '<span class="' + className + '">' + (index + 1) + '</span>';
              },
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: true, //修改swiper的父元素时，自动初始化swiper
              autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
            })
          })
        }
      })

      let data02 = {
        // channelUuid: '01370001',
        entTApplicationUuid: _this.webIdGroup,
        channelUuid: '00190001',
        pageNum: 1,
        pageSize: 6
      }
      picArticles(data02).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.newsLeft02 = data.rows
          this.$nextTick(() => {
            new Swiper("#bannerHomeSec02", {
              effect : 'fade',
              speed: 300,
              autoplay: 2000,
              loop: true,
              pagination : '.swiper-pagination-sec02',
              paginationClickable: true,
              paginationBulletRender: function (swiper, index, className) {
                  return '<span class="' + className + '">' + (index + 1) + '</span>';
              },
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: true, //修改swiper的父元素时，自动初始化swiper
              autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
            })
          })
        }
      })
    },
    // 企业内刊
    homeNewsPeriodical() {
      var _this = this
      
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01370003',
        pageNum: 1,
        pageSize: 7
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.homeNewsPeriodicalList1 = data.rows
        }
      })
      let data2 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01390002',
        pageNum: 1,
        pageSize: 7
      }
      articles(data2).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.homeNewsPeriodicalList2 = data.rows
        }
      })
      let data3 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01460001',
        pageNum: 1,
        pageSize: 7
      }
      articles(data3).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.homeNewsPeriodicalList3 = data.rows
        }
      })
    },
    
    // 党建
    homeNewsDj() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01460004',
        pageNum: 1,
        pageSize: 8
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.homeNewsDjList = data.rows
        }
      })
    },
    // 学习教育
    homeNewsXxjy() {
      var _this = this

      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '0147',
        pageNum: 1,
        pageSize: 8
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.homeNewsXxjyList = data.rows
        }
      })
    },
    swiper01() {
      new Swiper("#bannerPoint01", {
        loop: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        },
        speed: 5000,
        updateOnImagesReady: true,
        slidesPerView: 4,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: true, 
      })
    },
    swiper02() {
      new Swiper("#bannerPoint02", {
        loop: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        },
        speed: 5000,
        updateOnImagesReady: true,
        slidesPerView: 4,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: true, 
      })
    },
    swiper03() {
      new Swiper("#bannerPoint03", {
        loop: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        },
        speed: 5000,
        updateOnImagesReady: true,
        slidesPerView: 4,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: true, 
      })
    },
    swiper04() {
      new Swiper("#bannerPoint04", {
        loop: true,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        },
        speed: 5000,
        updateOnImagesReady: true,
        slidesPerView: 4,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: true, 
      })
    },
    // 新闻图片
    bannerPoint(){
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01380002',
        pageNum: 1,
        pageSize: 15
      }
      picArticles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.pointImgList01 = data.rows
          this.$nextTick(() => {
            _this.swiper01()
            // new Swiper("#bannerPoint01", {
            //   loop: true,
            //   autoplay: {
            //     delay: 0,
            //     stopOnLastSlide: false,
            //     disableOnInteraction: true,
            //     pauseOnMouseEnter: true
            //   },
            //   speed: 5000,
            //   updateOnImagesReady: true,
            //   slidesPerView: 4,
            //   spaceBetween: 10,
            //   observer: true,
            //   observeParents: true,
            //   autoplayDisableOnInteraction: true, 
            // })
          })
        }
      })

      let data1 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01360005',
        pageNum: 1,
        pageSize: 15
      }
      picArticles(data1).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.pointImgList02 = data.rows
          this.$nextTick(() => {
            _this.swiper02()
            // new Swiper("#bannerPoint02", {
            //   loop: true,
            //   autoplay: {
            //     delay: 0,
            //     stopOnLastSlide: false,
            //     disableOnInteraction: true,
            //     pauseOnMouseEnter: true
            //   },
            //   speed: 5000,
            //   updateOnImagesReady: true,
            //   slidesPerView: 4,
            //   spaceBetween: 10,
            //   observer: true,
            //   observeParents: true,
            //   autoplayDisableOnInteraction: true, 
            // })
          })
        }
      })

      let data2 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01360006',
        pageNum: 1,
        pageSize: 15
      }
      picArticles(data2).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.pointImgList03 = data.rows
          // console.log('测试',_this.pointImgList03)
          this.$nextTick(() => {
            _this.swiper03()
            // new Swiper("#bannerPoint03", {
            //   loop: true,
            //   autoplay: {
            //     delay: 0,
            //     stopOnLastSlide: false,
            //     disableOnInteraction: true,
            //     pauseOnMouseEnter: true
            //   },
            //   speed: 5000,
            //   updateOnImagesReady: true,
            //   slidesPerView: 4,
            //   spaceBetween: 10,
            //   observer: true,
            //   observeParents: true,
            //   autoplayDisableOnInteraction: true, 
            // })
          })
        }
      })

      let data3 = {
        entTApplicationUuid: _this.webId,
        channelUuid: '01380003',
        pageNum: 1,
        pageSize: 15
      }
      picArticles(data3).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.pointImgList04 = data.rows
          this.$nextTick(() => {
            _this.swiper04()
            // new Swiper("#bannerPoint04", { 
            //   loop: true,
            //   autoplay: {
            //     delay: 0,
            //     stopOnLastSlide: false,
            //     disableOnInteraction: true,
            //     pauseOnMouseEnter: true
            //   },
            //   speed: 5000,
            //   updateOnImagesReady: true,
            //   slidesPerView: 4,
            //   spaceBetween: 10,
            //   observer: true,
            //   observeParents: true,
            //   autoplayDisableOnInteraction: true, 
            // })
          })
        }
      })


    }
  }
}
</script>
<style>
.wrap{
  width: 1000px;
}
.home_pre{
  font-size: 18px;
    line-height: 60px;
    margin-bottom: 20px;
}
.mainHome {
    margin-top: 20px;
}
.bannerHome {
  height: 620px;
  position: relative;
}
.bannerHomeSec01 p{
    position: absolute;
    width: 100%;
    height: 35px;
    line-height: 35px;
    padding: 2px;
    
    bottom: 0;
    color: #fff;
    font-size: 12px;
    background-color: rgba(0,0,0,0.7);
    box-sizing: border-box;
    text-align: left;
}
.bannerHome .swiper-wrapper,
.bannerHome .swiper-slide,
.bannerHome img {
  height: 100%;
}
.bannerHome img {
  width: 100%;
}
.swiper-pagination-banner {
  color: #fff;
  font-size: 16px;
}
.swiper-pagination-current {
  font-size: 20px;
}

.homeNewsHd{
  height: 40px;
  width: 100%;
  border-bottom: 1px #0062B3 solid;
  text-align: left;
  margin-bottom: 10px;
}
.homeNewsHd .homeNewsBox_1 .com{
    padding-left: 0px;
    padding-top: 5px;
}
.homeNewsBox_1 .com li {
    width: 405px;
    padding-left: 15px;
    height: 30px;
    line-height: 30px;
    background-image: url(../assets/img/yianli.jpg);
    background-position: left center;
    background-repeat: no-repeat;
}
.homeNewsBox_1 .com .comDate{
  font-size: 15px;
  float:right;
}
.homeNewsBox_1 .com .comLi{
    white-space: nowrap;
    display: block;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    color: #000000;
    font-size: 14px;
}
.homeNewsHd span {
    width: 110px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    vertical-align: top;
    color: #1970b9;
    margin-right: 0;
    background-color: #ffffff;;
    text-align: center;
    font-size: 15px;
}

.homeNewsHd span {
    width: 110px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    vertical-align: top;
    color: #0170C1;
    margin-right: 0;
    background-color: #ffffff;
    text-align: center;
    font-size: 15px;
}
.homeNewsBox_1 .tj{
    width: 100%;
    height: 85px;
    overflow: hidden;
    line-height: 24px;
    color: #939393;
    margin-top: 18px;
}
.homeNewsBox_1 .clearLine{
  height: 20px;
  clear: both;
  display: block;
  width: 420px;
}
.tj a {
    width: 100%;
    overflow: hidden;
    display: block;
    color: #0263B2;
    font-size: 15px;
}
.tj p {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-indent: 2em;
}
.homeNewsHd span.active a {
    background-color: #0170C1;
    color: #fff;
    font-size: 15px;
}
.homeNewsBox_1{
  width: 420px;
  height: 100%;
  overflow: hidden;
  float: right;
  position: absolute;
  right: 0px;
  top: 0px;
}
.news_list li {
    line-height: 30px;
    position: relative;
    font-size: 14px;
}

.fr{
  font-size: 15px;
}


.homeDec02Top .right{
    width: 245px;
    border: 1px solid rgb(159, 191, 216);
    top: 0px;
    margin-top: 0px;
    right: 0px;
    position: absolute;
    height: 265px;
    padding: 1px;
}
.right .tit{
    width: 100%;
    height: 40px;
    background-image: url(../assets/img/zcq.png);
}
.right .tit .b{
    width: 105px;
    height: 30px;
    text-align: center;
    padding-top: 5px;
    margin-top: 5px;
    margin-left: 7px;
    float: left;
    line-height: 30px;
    color: rgb(1, 98, 179);
    font-size: 15px;
}


.homeDec03 .homeNewsPeriodical{
    width: 348px;
    overflow: hidden;
    float: left;
}

.homeDec03 .homeNewsBasic{
    width: 348px;
    overflow: hidden;
    float: left;
    margin-left: 26px;
}
.homeDec03 .more{
    width: 40px;
    height: 30px;
    float: right;
    text-align: center;
    display: block;
    margin-top: 10px;
    font-size: 13px;
    color: #0061B4;
}

.homeDec03 .comUL li {
      width: 333px;
    padding-left: 15px;
    height: 32px;
    line-height: 32px;
    background-image: url(../assets/img/li1.jpg);
    background-position: left center;
    background-repeat: no-repeat;
}
.homeDec03 .comUL li a {
    white-space: nowrap;
    display: block;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    font-size: 14px;
}
.homeDec03 .rightPart{
  width: 249px;
  float: right;
  overflow: hidden;
}
.homeDec03 .rightPart img {
    width: 245px;
    border: 1px #9FBFD8 solid;
    padding: 1px;
}

.about{
  width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}
.com_t{
  width: 100%;
  height: 40px;
  border-bottom: 1px #0062B3 solid;
}
.com_t .tab a {
  width: 110px;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  display: block;
  float: left;
}

.com_t .more{
    width: 40px;
    height: 30px;
    float: right;
    text-align: center;
    display: block;
    margin-top: 10px;
    font-size: 13px;
    color: #0061B4;
}
.about .con{
    width: 990px;
    height: 280px;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 20px;
    display: none;
}
.link{
    width: 1000px;
    margin: 0 auto;
    padding: 10px 0px;
    overflow: hidden;
}
.link .c{
    width: 998px;
    overflow: hidden;
    padding: 10px 0px;
    line-height: 25px;
    border-bottom: 1px #0062B3 solid;
    border-right: 1px #0062B3 solid;
    border-left: 1px #0062B3 solid;
}
.link .c a {
    display: block;
    padding: 0px 10px;
    float: left;
}
.com_t .tab{
    overflow: hidden;
    float: left;
    widows: 600px;
}

.bannerHomeSec01 .swiper-wrapper,
.bannerHomeSec01 .swiper-slide,
.bannerHomeSec01 img {
  height: 100%;
}
.bannerHomeSec01 img {
  width: 100%;
  height: 100%;
}
.bannerHomeSec01 .swiper-slide {
  position: relative;
}

.sec_zfgs_list a {
    display: block;
    text-align: center;
    line-height: 34px;
    border: 1px solid #d5d5d5;
    margin-bottom: 6px;
}


.homeNoticeL {
  width: 57px;
  height: 28px;
  overflow: hidden;
  border: solid 1px #0D65AC;
  line-height: 28px;
  margin: 17px 0px 0px 0;
  box-sizing: border-box;
}
.homeNoticeBox {
  margin-left: 88px;
  height: 100%;
}
.bannerHomeNotice a {
  font-size: 18px;
}
.bannerHomeNotice a:hover {
  color: #0D65AC;
}
.bannerHomeNotice .swiper-slide{
  width: 300px;
  width: auto;
}
#bannerHomeNotice>.swiper-wrapper {
  -webkit-transition-timing-function: linear; 
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
}

.homeDec02 .comul li {
    width: 410px;
    padding-left: 15px;
    height: 32px;
    line-height: 32px;
    background-image: url(../assets/img/li1.jpg);
    background-position: left center;
    background-repeat: no-repeat;
}
.homeDec02Img img{
  width: 100%;
  height: 100%;
}
.homeDec02BottomImg img {
  width: 100%;
  height: 100%;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.homeDec02BottomImg img:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.homeDec02BottomImg {
  height: 171px;
  overflow: hidden;
}
.homeDec02Bottom {
  margin-top: 0px;
}
.homeNewsBasicTop h3 {
  line-height: 30px;
}
.homeNewsBasicTop h3 a {
  color: #000;
  font-weight: 700;
  font-size: 18px;
}
.homeNewsBasicTop h3 a:hover {
  color: #0D65AC;
}
.homeNewsBasicTop p {
  color: #8b8a8a;
  font-size: 16px;
  line-height: 30px;
  margin-top: 15px;
  text-indent: 2em;
}
.homeNewsBasicTop p a:hover {
  text-decoration: underline;
}

.el-carousel__container{
  height: 98px;
}

.bannerHomeSec01 .swiper-wrapper,
  .bannerHomeSec01 .swiper-slide,
  .bannerHomeSec01 img {
    height: 100%;
  }

  .bannerHomeSec01 img {
    width: 100%;
    height: 100%;
  }

  .bannerHomeSec01 .swiper-slide {
    position: relative;
  }
  .com li:hover,.comul li:hover{
    color: #0061B4;
  }
  .com li:hover p a ,.comul li:hover p a {
    color: #0061B4;
  }
  .comUL li:hover{
    color: #0061B4;
  }
  .comUL li:hover p a {
    color: #0061B4;
  }
  .specailBox .swiper-container .swiper-wrapper{
    -webkit-transition-timing-function: linear; 
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
  }
  .bannerPoint>.swiper-wrapper {
    -webkit-transition-timing-function: linear; 
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
    margin: 0 auto;
  }

  .link .c a:hover{
    color: #0170C1;
  }
  .bannerHomeSec01 .swiper-pagination {
    

    cursor: pointer;
    bottom: 5px;
    right: 0px;
    position: absolute;
    height: 22px;
    text-align: right;
    z-index: 200;
    font: 12px/15px "\5B8B\4F53";
}
.bannerHomeSec01 .swiper-pagination-bullet {
    width: 15px;
    height: 16px;
    border-radius: 0px;
    background-color: #ffff;
    opacity: 1;
    font-size: 12px;
    text-align: center;
    margin: 0px 4px !important;
    color: #222;
}

.bannerHomeSec01 .swiper-pagination-bullet-active {
    background-color: #CE251F;
    color: #fff;
}

</style>